'use strict';

var Quiz = require("../types/Quiz.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Answer = require("../types/Answer.bs.js");
var Question = require("../types/Question.bs.js");
var ArrayUtils = require("../utils/ArrayUtils.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function str(prim) {
  return prim;
}

((require("./QuizComponent.css")));

function updateAnswer(setState, answer, _event) {
  return Curry._1(setState, (function (state) {
                return {
                        page: state.page,
                        selectedAnswer: answer
                      };
              }));
}

function showSelectedAnswer(state) {
  var match = state.selectedAnswer;
  if (match !== undefined) {
    var answer = match;
    var match$1 = Answer.correctAnswer(answer);
    var match$2 = Answer.youtubeUrl(answer);
    var match$3 = Answer.imageUrl(answer);
    return React.createElement("div", {
                className: "pt-8 md:pt-12"
              }, React.createElement("h2", {
                    className: "font-bold mb-2 leading-tight " + (
                      match$1 ? "text-green-600" : "text-red-600"
                    )
                  }, Answer.title(answer)), React.createElement("div", {
                    className: "pt-1"
                  }, $$Array.mapi((function (index, d) {
                          return React.createElement("p", {
                                      key: String(index),
                                      className: "text-lg"
                                    }, d);
                        }), Answer.description(answer))), React.createElement("div", {
                    className: "mt-4"
                  }, match$2 !== undefined ? React.createElement("div", {
                          className: "quiz-component__video-wrapper rounded-lg overflow-hidden border-2 border-gray-700 bg-gray-100"
                        }, React.createElement("iframe", {
                              className: "w-full",
                              height: "auto",
                              src: match$2
                            })) : null), React.createElement("div", {
                    className: "mt-4"
                  }, match$3 !== undefined ? React.createElement("div", {
                          className: "rounded-lg overflow-hidden"
                        }, React.createElement("img", {
                              className: "w-full",
                              src: match$3
                            })) : null));
  } else {
    return null;
  }
}

function showQuestion(quiz, question, setState, state, totalQuestions, currentQuestionIndex) {
  return React.createElement("div", {
              className: "pt-4 pb-6 pl-3 pr-4 md:px-0"
            }, React.createElement("div", {
                  className: "quiz-component__container border-2 border-gray-800 rounded-lg bg-orange-100 px-6 py-6 md:px-10"
                }, React.createElement("div", {
                      className: "inline-flex leading-tight text-sm text-orange-800 font-semibold bg-orange-200 py-1 px-1 rounded"
                    }, React.createElement("p", {
                          className: "mr-1"
                        }, String(currentQuestionIndex + 1 | 0) + (" of " + (String(totalQuestions) + " -"))), React.createElement("p", undefined, Quiz.title(quiz))), React.createElement("h1", {
                      className: "font-bold pt-1 pb-2 leading-tight break-words"
                    }, Question.title(question)), React.createElement("div", undefined, $$Array.map((function (answer) {
                            return React.createElement("div", {
                                        key: Answer.option(answer)
                                      }, React.createElement("button", {
                                            className: "btn border-2 border-gray-800 bg-white hover:bg-gray-900 hover:text-white focus:text-white focus:bg-gray-900 button-xl mt-3 w-full",
                                            onClick: (function (param) {
                                                return updateAnswer(setState, answer, param);
                                              })
                                          }, Answer.option(answer)));
                          }), Question.answers(question)))), React.createElement("div", undefined, showSelectedAnswer(state)));
}

function showSuccess(quiz) {
  return React.createElement("div", {
              className: "px-3 md:px-0 py-4 "
            }, React.createElement("div", {
                  className: "quiz-component__container border-2 border-gray-800 rounded-lg bg-orange-100 px-6 py-6 md:px-10"
                }, React.createElement("h2", {
                      className: "text-green-600 leading-tight"
                    }, Quiz.successMessage(quiz)), React.createElement("a", {
                      className: "btn border-2 border-gray-800 bg-white hover:bg-gray-900 hover:text-white focus:text-white focus:bg-gray-900 button-xl mt-3",
                      href: Quiz.readMore(quiz),
                      target: "_blank"
                    }, "Read More")), React.createElement("button", {
                  className: "btn border-2 border-gray-800 bg-white hover:bg-gray-900 hover:text-white focus:text-white focus:bg-gray-900 button-xl mt-10",
                  onClick: (function (param) {
                      return ReasonReactRouter.push("/");
                    })
                }, React.createElement("span", {
                      className: "mr-1"
                    }, React.createElement("svg", {
                          className: "w-4",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, React.createElement("path", {
                              d: "M23.6 10.12L20.9 8l-8.27-6.46a1 1 0 0 0-1.24 0L3.14 8 .4 10.12a1 1 0 0 0-.18 1.41 1 1 0 0 0 .8.39 1 1 0 0 0 .61-.22l1.13-.88v9a2.84 2.84 0 0 0 2.84 2.84h4.67V12.5h3.48v10.16h4.67a2.84 2.84 0 0 0 2.84-2.84v-9l1.13.88a1 1 0 0 0 .61.22 1 1 0 0 0 .8-.39 1 1 0 0 0-.18-1.42zm-4.37 9.7a.83.83 0 0 1-.83.83h-2.66V11.5a1 1 0 0 0-1-1h-5.5a1 1 0 0 0-1 1v9.16H5.6a.83.83 0 0 1-.83-.83V9.25l3.5-2.74L12 3.6l3.6 2.8 3.63 2.83z",
                              fill: "currentColor"
                            }))), "Home"));
}

function QuizComponent$Link(Props) {
  var href = Props.href;
  var onClick = Props.onClick;
  var className = Props.className;
  var children = Props.children;
  return React.createElement("a", {
              className: className,
              href: href,
              onClick: (function (e) {
                  e.preventDefault();
                  ReasonReactRouter.push(href);
                  return Curry._1(onClick, e);
                })
            }, children);
}

var Link = {
  make: QuizComponent$Link
};

function showQuiz(quiz, questions, setState, state, baseUrl, currentQuestionIndex) {
  var totalQuestions = questions.length;
  var currentQuestion = ArrayUtils.getOpt(currentQuestionIndex, questions);
  var isLastQuestion = currentQuestionIndex >= (totalQuestions - 1 | 0);
  var match = state.selectedAnswer;
  return React.createElement("div", undefined, React.createElement("div", undefined, currentQuestion !== undefined ? showQuestion(quiz, currentQuestion, setState, state, totalQuestions, currentQuestionIndex) : null), React.createElement("div", {
                  className: "max-w-screen-sm mx-auto"
                }, React.createElement("div", {
                      className: "px-3 md:px-0 pb-4"
                    }, match !== undefined ? (
                        isLastQuestion ? React.createElement("button", {
                                className: "btn border-2 border-green-600 bg-green-500 text-white hover:bg-green-600 hover:text-white focus:text-white focus:bg-green-600 button-xl w-full",
                                onClick: (function (param) {
                                    return Curry._1(setState, (function (state) {
                                                  return {
                                                          page: /* Complete */1,
                                                          selectedAnswer: state.selectedAnswer
                                                        };
                                                }));
                                  })
                              }, "Complete") : React.createElement(QuizComponent$Link, {
                                href: "/" + (baseUrl + ("/" + (currentQuestionIndex + 2 | 0).toString())),
                                onClick: (function (param) {
                                    return Curry._1(setState, (function (state) {
                                                  return {
                                                          page: state.page,
                                                          selectedAnswer: undefined
                                                        };
                                                }));
                                  }),
                                className: "btn border-2 border-gray-800 bg-orange-100 hover:bg-gray-900 hover:text-white focus:text-white focus:bg-gray-900 button-xl w-full",
                                children: null
                              }, "Next Question", React.createElement("span", {
                                    className: "ml-2"
                                  }, React.createElement("svg", {
                                        className: "w-5",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, React.createElement("path", {
                                            d: "M19.92 12.38a1 1 0 0 0 0-.76 1 1 0 0 0-.21-.33l-7-7a1 1 0 0 0-1.42 1.42l5.3 5.3H5a1 1 0 0 0 0 2h11.6l-5.3 5.3a1 1 0 1 0 1.42 1.42l7-7a1 1 0 0 0 .21-.33z",
                                            fill: "currentColor"
                                          }))))
                      ) : null)));
}

function QuizComponent(Props) {
  var quiz = Props.quiz;
  var questionNo = Props.questionNo;
  var baseUrl = Props.baseUrl;
  var match = React.useState((function () {
          return {
                  page: /* Quiz */0,
                  selectedAnswer: undefined
                };
        }));
  var state = match[0];
  var questions = Quiz.questions(quiz);
  var match$1 = state.page;
  return React.createElement("div", undefined, match$1 ? showSuccess(quiz) : showQuiz(quiz, questions, match[1], state, baseUrl, questionNo));
}

var make = QuizComponent;

exports.str = str;
exports.updateAnswer = updateAnswer;
exports.showSelectedAnswer = showSelectedAnswer;
exports.showQuestion = showQuestion;
exports.showSuccess = showSuccess;
exports.Link = Link;
exports.showQuiz = showQuiz;
exports.make = make;
/*  Not a pure module */
