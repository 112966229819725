'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Answer = require("./Answer.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function make(title, description, $staropt$star, answers, hint) {
  var imageUrl = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  return {
          title: title,
          description: description,
          answers: answers,
          hint: hint,
          imageUrl: imageUrl
        };
}

function title(t) {
  return t.title;
}

function description(t) {
  return t.description;
}

function answers(t) {
  return t.answers;
}

function hint(t) {
  return t.hint;
}

function imageUrl(t) {
  return t.imageUrl;
}

function makeArray(questions) {
  return $$Array.map((function (a) {
                return make(a.title, a.description, Caml_option.some(Caml_option.nullable_to_opt(a.imageUrl)), Answer.makeArray(a.answers), a.hint);
              }), questions);
}

exports.make = make;
exports.title = title;
exports.description = description;
exports.answers = answers;
exports.hint = hint;
exports.imageUrl = imageUrl;
exports.makeArray = makeArray;
/* No side effect */
