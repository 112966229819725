'use strict';

var Caml_format = require("bs-platform/lib/js/caml_format.js");

function parse(questionFragment) {
  try {
    var v = Caml_format.caml_int_of_string(questionFragment);
    return v < 0 ? 0 : v - 1 | 0;
  }
  catch (exn){
    return ;
  }
}

var Quiz = {
  parse: parse
};

exports.Quiz = Quiz;
/* No side effect */
